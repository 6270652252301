import * as React from "react";

import {
  Box,
  Text,
  Flex,
  Heading,
  Image,
  Button,
  Center,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Link } from "gatsby";
import "./Solutions.css";
import logozeus from "../../images/zeus.svg";
import loop from "../../images/loop.png";

import logoEligibilite from "../../images/logoEligibilite.png";
import "./Solutions.css";

const Solutions = () => (
  <>
    <Box className="solutionsContent" id="Solutions">
      <Heading as={"h2"} size="lg" textAlign={"center"} pt={"50px"} pb={"50px"}>
        Solutions
      </Heading>
      <Flex
        justify={"space-between"}
        ml={"10%"}
        mr={"10%"}
        pb={"150px"}
        display={{ base: "block", md: "flex" }}
      >
        <Link to="Depistage">
          <Box
            className="solutionBox"
            p={"20px"}
            alignItems={"center"}
            maxW="sm"
            mb={{ base: "40px", xl: "initial", md: "initial", sm: "initial" }}
            mr={{ base: "initial", xl: "40px", lg: "20px", md: "initial" }}
          >
            <Image
              align={"center"}
              boxSize={"200px"}
              src={logozeus}
              alt="icon zeus"
              width={"200px"}
              margin={"auto"}
            />
            <hr align="center" />
            <Heading as={"h3"} size="md" align={"center"}>
              Logiciel de dépistage organisé
            </Heading>
            <Text>
              Zeus, est un logiciel de gestion des dépistages organisées des
              cancers en France métropolitaine et Outre-Mer. Ce logiciel répond
              au cahier des charges nationales de l’INCA. Notre solution
              informatique permet aux centres régionaux de coordination des
              dépistages des cancers la gestion des différents dépistages
              (Dépistage du Cancer du Sein, Colon, Col de l’Utérus et Poumon).
              Nous mettons à disposition des outils de qualité permettant de
              répondre aux missions des CRCDC.
            </Text>
            <Center>
              <Button rightIcon={<ChevronRightIcon />}>En savoir plus</Button>
            </Center>
          </Box>
        </Link>
        <Link to="Automate">
          <Box
            className="solutionBox"
            p={"20px"}
            maxW="sm"
            mb={{ base: "40px", xl: "initial", md: "initial", sm: "initial" }}
            mr={{ base: "initial", xl: "40px", lg: "20px", md: "initial" }}
          >
            <Image
              src={loop}
              width={"auto"}
              height={"auto"}
              alt="icon zeus"
              align={"center"}
              margin={"auto"}
            />
            <hr />
            <Heading as={"h3"} size="md" align={"center"}>
              Logiciel pour automate de lecture{" "}
            </Heading>
            <Text>
              LoopDeeLab est un logiciel de pilotage en partenariat avec la
              société Loop Dee Science, permettant le maniement d'une flotte
              d'automate de lecture et d'avoir un suivi des analyses biologiques
              de manière numérique. Les automates réalisent des analyses rapides
              de différents pathogènes tels que le COVID-19.
            </Text>
            <Center>
              <Button rightIcon={<ChevronRightIcon />}>En savoir plus</Button>
            </Center>
          </Box>
        </Link>
        <Link to="Gestion">
          <Box
            className="solutionBox"
            p={"20px"}
            maxW="sm"
            mb={{ base: "40px", xl: "initial", md: "initial", sm: "initial" }}
          >
            <Text fontSize="6xl" className="title" fontWeight={600} wra>
              Gaïa
            </Text>

            <hr />
            <Heading as={"h3"} size="md" align={"center"}>
              Logiciel de gestion de laboratoire d'analyse microbiologie
            </Heading>
            <Text margin="auto">
              GAIA est une solution LIMS (Système de gestions des informations
              de laboratoire) personnalisée pour les besoins du laboratoire en
              micro-biologie ADGène. Cet outils permettant la gestion des
              clients, la réalisation des devis et des commandes, la reception
              des échantillons, l’enregistrement des résultat et la transmission
              des résultats soit par mail soit sur une plateforme sécurisée.
            </Text>
            <Center>
              <Button rightIcon={<ChevronRightIcon />} margin={"auto"}>
                En savoir plus
              </Button>
            </Center>
          </Box>
        </Link>
      </Flex>
    </Box>
  </>
);
export default Solutions;
