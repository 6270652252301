import * as React from "react";

import { Box, Flex, Heading, Text, Badge, Image, Link } from "@chakra-ui/react";

import articles from "../data/article.json";
import "./Actualite.css";


function Actualite() {
 
  return (
    <>
      <Box className="ActualiteContent" id="Actualite">
        <Heading
          as={"h2"}
          size="lg"
          textAlign={"center"}
          pt={"10px"}
          pb={"50px"}
        >
          Les actualités
        </Heading>
        <Flex
          /* display={{base:"initial" ,xl:"block",md:"initial"}}*/
          flexDirection={{ base: "column", md: "row" }}
          justify={"space-evenly"}
          alignItems={{ base: "center", md: "normal" }}
          /*ml={{base:"0" ,md:"10%"}}*/

          pb={"50px"}
          height={{ base: "900px", md: "600px" }}
        >
          {articles.map((article) => (
            <>
              <Link href={article.url} isExternal>
                <Box
                  className="articleImage"
                  borderRadius={"5"}
                  backgroundColor={"rgb(1, 0, 29,75%)"}
                >
                  <Image
                    align={"center"}
                    width={{ base: "250px", xl: "460px", sm: "250px" }}
                    height={"auto"}
                    maxHeight={"386px"}
                    src={article.imageURL}
                    alt="icon zeus"
                  />
                  <Box
                    className="articleBox"
                    width={{ base: "250px", xl: "460px", sm: "250px" }}
                    height={"auto"}
                    p={"20px"}
                  >
                    <Box>
                      <Text>{article.Date}</Text>
                    </Box>
                    <Box>
                      <Badge
                        borderRadius="full"
                        px="4"
                        color="#fafafa"
                        textColor={"#01001d"}
                      >
                        Actualité
                      </Badge>
                    </Box>
                    <Box>
                      <Box
                        mt="1"
                        fontWeight="semibold"
                        as="h4"
                        lineHeight="tight"
                        className="articleName"
                      >
                        <hr />
                        {article.Name}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Link>
            </>
          ))}
        </Flex>
      </Box>
    </>
  );
}
export default Actualite;
