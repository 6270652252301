import * as React from "react";

import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import "./home.css";

function Home() {
  return (
    <>
      <Box className="homeContent" id="home">
        <Box className="homeBackground">
          <Flex
            justify={"space-between"}
            pt={"150px"}
            pb={"150px"}
            ml={"10%"}
            mr={"10%"}
            display={{ base: "block", md: "flex" }}
          >
            <Box w={{ base: "100%", md: "40%" }}>
              <hr />
              <Heading size="lg" mb={"5px"}>
                Notre entreprise
              </Heading>
              <Heading as={"h3"} size="md">
                Qui commes nous ?
              </Heading>
              <Text>
                La société OSI SANTE France SAS a été créée en 2018 suite au
                rachat du fonds de commerce d’OSI Sante SARL, active dans le
                secteur des dépistages organisés depuis 2001. Le président et la
                directrice générale d’OSI SANTE France sont respectivement M.
                Sorin CIOCEA et Mme Stéphanie LABADIE. Le siège d’exploitation
                de la société OSI SANTE France se trouve à Caen en Normandie.
              </Text>
            </Box>
            <Box
              w={{ base: "100%", md: "40%" }}
              pt={{ md: "250px", base: "50px" }}
            >
              <hr />
              <Heading as={"h1"} size="md">
                Éditeur de logiciel
              </Heading>
              <Text>
                Les activités d’OSI SANTE France sont orientées vers les
                logiciels de gestion à destination des Structures de Gestion du
                Dépistage Organisé. 34 départements en France métropolitaine et
                Outre-Mer représentés par 6 Régions sont équipés actuellement
                avec les solutions logicielles d’OSI SANTE France, qui propose
                une solution régionale intégrée multi-dépistages.
              </Text>
            </Box>
          </Flex>
        </Box>
      </Box>
    </>
  );
}
export default Home;
