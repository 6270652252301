import * as React from "react";

import { Box, Flex, Heading, Text, Image } from "@chakra-ui/react";
import "./Plateforme.css";
import fluidite from "../../images/fluidite.svg";
import ergonomie from "../../images/ergo-integration.svg";
import security from "../../images/security.svg";
import support from "../../images/support.svg";

function Home() {
  return (
    <>
      <Box className="plateformeContent" id="Plateforme" pb={"50px"}>
        <Heading
          as={"h2"}
          size="lg"
          color={"#01001D"}
          textAlign={"center"}
          pt={"50px"}
        >
          Plateforme OSI SANTÉ
        </Heading>

        <Flex
          ml={"10%"}
          mr={"10%"}
          pt={"50px"}
          className="valeur"
          /* flexDirection={{ base: "column", md: "row" }}*/
        >
          <Box display={{ base: "none", md: "initial" }}>
            <Image width={"auto"} height={"200px"} src={fluidite} alt="" />
          </Box>
          <Box pl={{ base: "0", md: "85px" }}>
            <hr />
            <Heading as={"h3"} size="md" color={"#01001D"}>
              Fluidité et rapidité de traitement des demandes{" "}
            </Heading>
            <Text color={"#01001D"}>
              L'équipe OSI Santé France est continuellement à l'écoute de ses
              clients et par l'intermédiaire de son équipe d'ingénieurs en
              développement et architecture logiciel ainsi que des outils
              modernes utilisés (Microsoft SQL Server, Symfony 6, ReactJS,
              RabbitMQ, etc), l'équipe saura être efficace et rapide pour
              répondre aux besoins.
            </Text>
          </Box>
        </Flex>
        <Flex ml={"10%"} mr={"10%"} pt={"50px"} className="valeur">
          <Box pl={{ base: "0", md: "85px" }}>
            <hr />
            <Heading as={"h3"} size="md" color={"#01001D"}>
              Ergonomie et intégration{" "}
            </Heading>
            <Text color={"#01001D"}>
              Chacune des solutions développées à OSI Santé France sont passées
              par une étape d'étude et de maquettage afin de travailler
              l'ergonomie et l'expérience utilisateur (UI/UX) de manière adaptée
              ainsi qu'accessible en faisant usage notamment d'outils tels
              qu'Adobe XD pour le maquettage, Lighthouse pour l'étude de
              l'interface (accessibilité, fluidité, etc) et par un feedback
              auprès de nos utilisateurs finaux.
            </Text>
          </Box>
          <Box display={{ base: "none", md: "initial" }} ml="5px">
            <Image width={"auto"} height={"200px"} src={ergonomie} alt="" />
          </Box>
        </Flex>
        <Flex ml={"10%"} mr={"10%"} pt={"50px"} className="valeur">
          <Box display={{ base: "none", md: "initial" }}>
            <Image width={"auto"} height={"200px"} src={security} alt="" />
          </Box>
          <Box pl={{ base: "0", md: "85px" }}>
            <hr />
            <Heading as={"h3"} size="md" color={"#01001D"}>
              {" "}
              Connectivité, sécurité et traçabilité
            </Heading>{" "}
            <Text color={"#01001D"}>
              Nous travaillons en partenariat avec CHEOPS Technology, hébergeur
              HADS (Hébergeur Agréé de Données de Santé) ainsi qu'avec un DPO
              (Data Protection Officer) afin de répondre aux exigences RGPD et
              respecter les nombreux ISO liées aux données de santé. Chacune de
              nos solutions dites sensibles font l'objet d'une traçabilité par
              l'intermédiaire d'une conservation des journaux d'évènements et
              des actions des utilisateurs.
            </Text>
          </Box>
        </Flex>
        <Flex ml={"10%"} mr={"10%"} pt={"50px"} className="valeur">
          <Box pl={{ base: "0", md: "85px" }}>
            <hr />
            <Heading as={"h3"} size="md" color={"#01001D"}>
              Support à votre service{" "}
            </Heading>
            <Text color={"#01001D"}>
              Notre support technique est à l'écoute de vos incidents et est
              disponible directement par téléphone, par tickets via l'outil JIRA
              ou encore par e-mail. L'équipe forte de compétences et
              d'expériences saura résoudre vos demandes dans le cadre de
              l'utilisation de nos solutions.
            </Text>
          </Box>
          <Box display={{ base: "none", md: "initial" }}>
            <Image width={"auto"} height={"200px"} src={support} alt="" />
          </Box>
        </Flex>
      </Box>
    </>
  );
}
export default Home;
