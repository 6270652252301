// src/pages/index.js
import React from "react";
import { Box } from "@chakra-ui/react";

import Header from "../components/Nav";
import Home from "../components/Home";
import Solutions from "../components/Solutions";
import Plateforme from "../components/Plateforme";
import Equipe from "../components/Equipe";
import Partenaire from "../components/Partenaire/Partenaire";
import Actualite from "../components/Actualité";
import Contact from "../components/contact";
import Footer from "../components/Footer";
import "../style/Global.css"


function IndexPage() {
  
  return (
    <>
      <Header />
      <Box className="content" backgroundColor={"#02011E"} width={"100%"}>
        <Home />
        <Solutions />
        <Plateforme />
        <Equipe />
        <Partenaire />
        <Actualite />
        <Contact />
      </Box>
      <Footer/>
    </>
  );
}

export default IndexPage;
