import * as React from "react";

import { Box, Flex, Heading, Image } from "@chakra-ui/react";

import corse from "../../images/Partenaire/corse.png";
import crcdc from "../../images/Partenaire/crcdc-logo.png";
import mayotte from "../../images/Partenaire/mayotte.svg";
import hf from "../../images/Partenaire/Logo_Hauts-de-France_2016.svg";
import paysloire from "../../images/Partenaire/paysloire.svg";
import reunion from "../../images/Partenaire/reunion.jpg";
import rhonealpe from "../../images/Partenaire/rhoneAlpe.png";
import kisano from "../../images/Partenaire/logo_kisano_w.svg";
import cheops from "../../images/Partenaire/logo-cheops-cloud.svg";
import "./Partenaire.css";

function Partenaire() {
  return (
    <>
      <Box className="partenaireContent" id="Partenaire">
        <Heading
          as={"h2"}
          size="lg"
          textAlign={"center"}
          pt={"50px"}
          pb={"50px"}
        >
          Partenaires et références{" "}
        </Heading>
        <Flex color="white" className="photoBanner">
          <Image
            boxSize={"200px"}
            src={kisano}
            width={"200px"}
            height={"auto"}
            margin={"auto"}
          />
          <Image
            boxSize={"200px"}
            src={cheops}
            width={"200px"}
            height={"auto"}
            margin={"auto"}
          />
          <Image
            boxSize={"200px"}
            src={corse}
            width={"200px"}
            height={"auto"}
            margin={"auto"}
          />
          <Image
            boxSize={"200px"}
            src={crcdc}
            width={"200px"}
            height={"auto"}
            margin={"auto"}
          />
          <Image
            boxSize={"200px"}
            src={mayotte}
            width={"200px"}
            height={"auto"}
            margin={"auto"}
          />
          <Image
            boxSize={"200px"}
            src={hf}
            width={"200px"}
            height={"auto"}
            margin={"auto"}
          />
          <Image
            boxSize={"200px"}
            src={paysloire}
            width={"200px"}
            height={"auto"}
            margin={"auto"}
          />
          <Image
            boxSize={"200px"}
            src={reunion}
            width={"200px"}
            height={"auto"}
            margin={"auto"}
          />
          <Image
            boxSize={"200px"}
            src={rhonealpe}
            width={"200px"}
            height={"auto"}
            margin={"auto"}
          />
          <Image
            boxSize={"200px"}
            src={kisano}
            width={"200px"}
            height={"auto"}
            margin={"auto"}
          />
          <Image
            boxSize={"200px"}
            src={cheops}
            width={"200px"}
            height={"auto"}
            margin={"auto"}
          />
          <Image
            boxSize={"200px"}
            src={corse}
            width={"200px"}
            height={"auto"}
            margin={"auto"}
          />

          <Image
            boxSize={"200px"}
            src={crcdc}
            width={"200px"}
            height={"auto"}
            margin={"auto"}
          />
        </Flex>
      </Box>
    </>
  );
}
export default Partenaire;
