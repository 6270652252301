import * as React from "react";

import {
  Box,
  Flex,
  Heading,
  List,
  ListItem,
  Image,
  Input,
  InputGroup,
  FormControl,
  FormLabel,
  InputRightElement,
  HStack,
  Textarea,
  Text,
  Link,
  Button,
} from "@chakra-ui/react";
import "./Contact.css";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import circle from "../../images/circle.svg";
import Linkedin from "../../images/linkedin.svg";
import LocationOnIcon from '@mui/icons-material/LocationOn';

function Contact() {
 
  return (
    <>
      <Box className="contactBox" id="Contact">
        <Heading
          as={"h2"}
          size="lg"
          textAlign={"center"}
          pt={"50px"}
          pb={{ base: "50px", md: "100px" }}
        >
          Contact{" "}
        </Heading>
        <Flex
          className="contactFlex"
          ml={"10%"}
          mr={"10%"}
          marginBottom={"50px"}
          flexDirection={{ base: "column", md: "row" }}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
        >
          <Box className="infoContact">
            <Heading as={"h3"} size="md" mb={{ base: "20px", md: "100px" }}>
              Où nous trouver ?{" "}
            </Heading>
            <List className="info">
    
              <ListItem>
                <EmailIcon color="#E6854D" size={20} />
                info@osi-sante.fr
              </ListItem>
              <ListItem>
                <LocationOnIcon color="#E6854D" size={20} />8
                rue des compagnons 14000 Caen
              </ListItem>
            </List>
            <Link
              href="https://www.linkedin.com/company/osi-sante-france/"
              isExternal
            >
              <HStack spacing="10px" mt={{ base: "20px", md: "50px" }} mb={{base:"50px", md: "initial"}} >
                <Image
                  src={Linkedin}
                  width={"50px"}
                  height={"auto"}
                  color={"#E6854D"}
                />
                <Text>Linkedin</Text>
              </HStack>
            </Link>
            <Image
              src={circle}
              width={{ base: "500px", xl: "1114px" }}
              display={{ base: "none", md: "initial" }}
              height={"auto"}
              mt={"50px"}
            />
          </Box>
          <Box className="formContact" margin={{ base:"auto", md:"initial"}}>
            <Heading as={"h3"} size="md" mb={"50px"}>
              Envoyez nous un message !{" "}
            </Heading>
            <form
              method="post"
              action="https://getform.io/f/64f3f712-cf7d-4652-84a5-be78f06a136f"
            >
              <HStack spacing="24px" className="group" flexDirection={{ base: "column", md: "row" }} alignItems={{base: "flex-end", md:"center"}}>
                <FormControl isRequired mb={{base:"20px", md:"initial"}}>
                  <FormLabel>Nom :</FormLabel>
                  <Input
                    placeholder="Entrez votre nom"
                    type="text"
                    name="name"
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Prénom :</FormLabel>
                  <Input
                    placeholder="Entrez votre prénom"
                    type="text"
                    name="firstname"
                  />
                </FormControl>
              </HStack>
              <FormControl isRequired className="group">
                <FormLabel>Adresse email :</FormLabel>
                <InputGroup>
                  <InputRightElement
                    children={
                      <EmailIcon
                        color="#02011E"
                        size={20}
                      />
                    }
                  />

                  <Input
                    type="email"
                    placeholder="example@mail.com"
                    name="email"
                  />
                </InputGroup>
              </FormControl>
              <FormControl isRequired className="group">
                <FormLabel>Votre message :</FormLabel>
                <Textarea
                  placeholder="Entrez votre message ..."
                  type="text"
                  name="message"
                />
              </FormControl>
              <Button variant="solid" type="submit">
                Envoyer
              </Button>
            </form>
          </Box>
        </Flex>
      </Box>
    </>
  );
}
export default Contact;
