import * as React from "react";

import {
  Box,
  Text,
  Flex,
  Heading,
  Image,
  List,
  ListItem,
} from "@chakra-ui/react";
import Stephanie from "../../images/Equipe/Stephanie.svg";
import Dom from "../../images/Equipe/Dom.svg";
import Freddie from "../../images/Equipe/Freddie.png";
import Maxence from "../../images/Equipe/Maxence.svg";
import Erwin from "../../images/Equipe/Erwin.png";
import Seb from "../../images/Equipe/Seb.svg";
import Sarah from "../../images/Equipe/Sarah.svg";
import WorkIcon from '@mui/icons-material/Work';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

import "./Equipe.css";

function Solutions() {
  return (
    <>
      <Box className="equipeContent" id="Equipe">
        <Heading
          as={"h2"}
          size="lg"
          textAlign={"center"}
          pt={"50px"}
          pb={"50px"}
        >
          Notre équipe{" "}
        </Heading>
        <Box ml={"10%"} mr={"10%"}>
          <Flex
            justify={"space-evenly"}
            pb={{ base: "20px", md: "50px" }}
            display={{ base: "block", md: "flex" }}
            flexWrap={{ base: "wrap", xl: "initial" }}
          >
            <Box
              className="employeBox"
              p={"20px"}
              alignItems={"center"}
              maxW="sm"
              mb={"20px"}
            >
              <Image
                align={"center"}
                boxSize={"200px"}
                src={Stephanie}
                alt="icon zeus"
                width={"200px"}
                margin={"auto"}
              />
              <hr align="center" />
              <Text fontSize="2xl" align={"center"}>
                Stéphanie Labadie
              </Text>
              <List className="info">
                <ListItem>
                  <WorkIcon color="#E6854D" size={20} />
                  Directrice général
                </ListItem>
                <ListItem>
                  <EmailIcon color="#E6854D" size={20} />
                  slabadie@osi-sante.fr
                </ListItem>
                <ListItem>
                  <HourglassEmptyIcon
                    color="#E6854D"
                    size={20}
                  />
                  20 ans d'ancienneté
                </ListItem>
              </List>
            </Box>
            <Box className="employeBox" p={"20px"} maxW="sm" mb={"20px"}>
              <Image
                src={Dom}
                width={"auto"}
                height={"200px"}
                alt="icon zeus"
                align={"center"}
                margin={"auto"}
              />
              <hr />
              <Text fontSize="2xl" align={"center"}>
                Dominique Menage{" "}
              </Text>
              <List className="info">
                <ListItem>
                  <WorkIcon color="#E6854D" size={20} />
                  Développeur
                </ListItem>
                <ListItem>
                  <EmailIcon color="#E6854D" size={20} />
                  dmenage@osi-sante.fr
                </ListItem>
                <ListItem>
                  <HourglassEmptyIcon
                    color="#E6854D"
                    size={20}
                  />
                  25 ans d'ancienneté
                </ListItem>
              </List>
            </Box>
            <Box className="employeBox" p={"20px"} maxW="sm" mb={"20px"}>
              <Image
                align={"center"}
                boxSize={"200px"}
                src={Freddie}
                alt="icon zeus"
                width={"200px"}
                margin={"auto"}
              />
              <hr />
              <Text fontSize="2xl" align={"center"}>
                Freddy Heudiard
              </Text>
              <List className="info">
                <ListItem>
                  <WorkIcon color="#E6854D" size={20} />
                  Développeur
                </ListItem>

                <ListItem>
                  <EmailIcon color="#E6854D" size={20} />
                  fheudiard@osi-sante.fr
                </ListItem>
                <ListItem>
                  <HourglassEmptyIcon
                    color="#E6854D"
                    size={20}
                  />
                  12 ans d'ancienneté
                </ListItem>
              </List>
            </Box>
            <Box className="employeBox" p={"20px"} maxW="sm" mb={"20px"}>
              <Image
                align={"center"}
                boxSize={"200px"}
                src={Maxence}
                alt="icon zeus"
                width={"200px"}
                margin={"auto"}
              />
              <hr />
              <Text fontSize="2xl" align={"center"}>
                Maxence Tasse
              </Text>
              <List className="info">
                <ListItem>
                  <WorkIcon color="#E6854D" size={20} />
                  Développeur
                </ListItem>

                <ListItem>
                  <EmailIcon color="#E6854D" size={20} />
                  mtasse@osi-sante.fr
                </ListItem>
                <ListItem>
                  <HourglassEmptyIcon
                    color="#E6854D"
                    size={20}
                  />
                  6 ans d'ancienneté
                </ListItem>
              </List>
            </Box>
          </Flex>
          <Flex
            justify={"space-evenly"}
            pb={"50px"}
            display={{ base: "block", md: "flex" }}
            flexWrap={{ base: "wrap", xl: "initial" }}
          >
            <Box className="employeBox" p={"20px"} maxW="sm" mb={"20px"}>
              <Image
                src={Erwin}
                width={"auto"}
                height={"200px"}
                alt="icon zeus"
                align={"center"}
                margin={"auto"}
              />
              <hr />
              <Text fontSize="2xl" align={"center"}>
                Erwin Caillaud
              </Text>
              <List className="info">
                <ListItem>
                  <WorkIcon color="#E6854D" size={20} />
                  Développeur
                </ListItem>

                <ListItem>
                  <EmailIcon color="#E6854D" size={20} />
                  ecaillaud@osi-sante.fr
                </ListItem>
                <ListItem>
                  <HourglassEmptyIcon
                    color="#E6854D"
                    size={20}
                  />
                  3 ans d'ancienneté
                </ListItem>
              </List>
            </Box>
            <Box className="employeBox" p={"20px"} maxW="sm" mb={"20px"}>
              <Image
                align={"center"}
                boxSize={"200px"}
                src={Seb}
                alt="icon zeus"
                width={"200px"}
                margin={"auto"}
              />
              <hr />
              <Text fontSize="2xl" align={"center"}>
                Sébastien Polvent{" "}
              </Text>
              <List className="info">
                <ListItem>
                  <WorkIcon color="#E6854D" size={20} />
                  Développeur
                </ListItem>

                <ListItem>
                  <EmailIcon color="#E6854D" size={20} />
                  spolvent@osi-sante.fr
                </ListItem>
                <ListItem>
                  <HourglassEmptyIcon
                    color="#E6854D"
                    size={20}
                  />
                  1 an d'ancienneté
                </ListItem>
              </List>
            </Box>
            <Box className="employeBox" p={"20px"} maxW="sm" mb={"20px"}>
              <Image
                align={"center"}
                boxSize={"200px"}
                src={Sarah}
                alt="icon zeus"
                width={"200px"}
                margin={"auto"}
              />
              <hr />
              <Text fontSize="2xl" align={"center"}>
                Sarah Pioline Benourdja{" "}
              </Text>
              <List className="info">
                <ListItem>
                  <WorkIcon color="#E6854D" size={20} />
                  Développeuse
                </ListItem>

                <ListItem>
                  <EmailIcon color="#E6854D" size={20} />
                  spioline@osi-sante.fr
                </ListItem>
                <ListItem>
                  <HourglassEmptyIcon
                    color="#E6854D"
                    size={20}
                  />
                  1 an d'ancienneté
                </ListItem>
              </List>
            </Box>
          </Flex>
        </Box>
      </Box>
    </>
  );
}
export default Solutions;
